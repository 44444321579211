import { genericService } from "./genericService";
import { akeronService } from "./akeronService";
const section = "HeaderTrip";

const FILTER_DATE = "FECHA";
const FILTER_DRIVER = "CHOFER";
const FILTER_VEHICLE = "CAMION";

function filter(param, from = 0, limit = 10) {
  console.log(param);
  return akeronService
    .make_post_request("HeaderTrip/ListCustomerHeaderTripNew", {
      FechaDesde: param.dateInicio_filter,
      FechaHasta: param.dateFin_filter,
      jtStartIndex: from,
      jtPageSize: limit,
      NumTrip: param.ruta_filter != "" ? parseInt(param.ruta_filter) : 0,
      NumRemito: param.remito_filter,
      idVehiculo: param.vehiculo_filter != null ? param.vehiculo_filter.id : 0,
      SoloPendientes: param.onlyPendientes,
      IdClient: param.cliente_filter != null ? param.cliente_filter.id : 0,
      Choferes: param.chofer_filter != null ? param.chofer_filter.map((chofer) => chofer.id) : [],
      Transportes: param.transporte_filter != null ? param.transporte_filter.map((transporte) => transporte.id) : [],
    })
    .then((response) => {
      let result = {
        quantity: response.quantity,
        list: response["headerTrips"],
      };
      return result;
    });
}

function filterOpen(param, from = 0, limit = 10) {
  return akeronService
    .make_post_request("HeaderTrip/ListCustomerHeaderTripRuteoNew", {
      FechaDesde: param.dateInicio_filter,
      FechaHasta: param.dateFin_filter,
      jtStartIndex: from,
      jtPageSize: limit,
      IdChofer: 0,
      NumTrip: 0,
      NumRemito: "",
      IdClient: 0,
    })
    .then((response) => {
      let result = {
        quantity: response.quantity,
        list: response["headerTrips"],
      };
      return result;
    });
}

function remove(id) {
  return genericService.remove(section, id);
}

function getAll() {
  return genericService.get("HeaderTrip/ListHeaderTrip");
}

function getRuta(id) {
  return genericService.get("HeaderTrip/findHeaderTripNew/" + id);
}

function create(item) {
  const param = {
    DateString: item.date + " " + item.time,
    IdOrigen: item.origen.id,
    IdDriver: item.driver.id,
    IdVehicle: item.vehicle.id,
    IdTypeCharge: item.typeCharge.id,
    IdDestination: item.destination.id,
    IdZone: item.zone.id,
    IdTypeTrip: item.typeTrip.id,
    BasicCost: item.basicCost,
    TotalCost: item.totalCost,
    Observation1: item.observation1,
    Observation2: item.observation2,
    Aditionals: item.aditionals,
    IdCompanyCustody: item.companyCustody != null ? item.companyCustody.id : 0,
    QuantityModules: item.quantityModules,
    IsHiddenDelivereds: item.isHiddenDelivereds,
  };
  console.log(param);
  return genericService.create(section, param);
}

function createNew(item) {
  const param = {
    DateString: item.date + " " + item.time,
    IdOrigen: item.origen.id,
    IdDriver: item.driver.id,
    IdVehicle: item.vehicle.id,
    IdTypeCharge: item.typeCharge.id,
    IdDestination: item.destination.id,
    IdZone: item.zone.id,
    IdTypeTrip: item.typeTrip.id,
    BasicCost: item.basicCost,
    TotalCost: item.totalCost,
    Observation1: item.observation1,
    Observation2: item.observation2,
    Aditionals: item.aditionals,
    IdCompanyCustody: item.companyCustody != null ? item.companyCustody.id : 0,
    QuantityModules: item.quantityModules,
    IsHiddenDelivereds: item.isHiddenDelivereds,
  };
  console.log("ruta new", param);
  return akeronService.make_post_request("HeaderTrip/CreateHeaderTripNew", param).then((response) => {
    return response;
  });
}

function updateNew(item) {
  const param = {
    numTrip: item.numTrip,
    id: item.id,
    DateString: item.date + " " + item.time,
    //HoraString: item.time,
    IdOrigen: item.origen.id,
    IdDriver: item.driver.id,
    IdVehicle: item.vehicle.id,
    IdTypeCharge: item.typeCharge.id,
    IdDestination: item.destination.id,
    IdZone: item.zone.id,
    IdTypeTrip: item.typeTrip.id,
    BasicCost: item.basicCost,
    TotalCost: item.totalCost,
    Observation1: item.observation1,
    Observation2: item.observation2,
    Aditionals: item.aditionals,
    IdCompanyCustody: item.companyCustody != null ? item.companyCustody.id : 0,
    QuantityModules: item.companyCustody != null ? 1 : item.quantityModules,
    IsHiddenDelivereds: item.isHiddenDelivereds,
  };
  return akeronService.make_put_request("HeaderTrip/UpdateHeaderTripNew", param).then((response) => {
    return response;
  });
}

function update(item) {
  const param = {
    numTrip: item.numTrip,
    id: item.id,
    DateString: item.date,
    IdOrigen: item.origen.id,
    IdDriver: item.driver.id,
    IdVehicle: item.vehicle.id,
    IdTypeCharge: item.typeCharge.id,
    IdDestination: item.destination.id,
    IdZone: item.zone.id,
    IdTypeTrip: item.typeTrip.id,
    BasicCost: item.basicCost,
    TotalCost: item.totalCost,
    Observation1: item.observation1,
    Observation2: item.observation2,
    QuantityAssistant: item.quantityAssistant,
    QuantityDelay: item.quantityDelay,
    QuantityStays: item.quantityStays,
    QuantityTurns: item.quantityTurns,
    IdCompanyCustody: item.companyCustody != null ? item.companyCustody.id : 0,
    QuantityModules: item.companyCustody != null ? 1 : item.quantityModules,
    IsHiddenDelivereds: item.isHiddenDelivereds,
  };
  return genericService.update(section, param);
}

function addImages(param) {
  console.log(param);
  return akeronService
    .make_post_request("HeaderTrip/AddImageHeaderTrip", {
      IdTrip: param.ruta,
      Image: param.images,
    })
    .then((response) => {
      return response;
    });
}

function getRutaById(id) {
  return genericService.get("HeaderTrip/FindHeaderTripByNumTrip/" + id);
}

function OrderDelivered(params) {
  return akeronService.make_post_request("/HeaderTrip/OrderDelivered", params).then((response) => {
    return response;
  });
}

function ChangeStateRemito(param) {
  /*
    "Id" : 180821,
    "IdTrip": 48396,
    "CodEstate": 3
  */
  return akeronService.make_post_request("DeliveredNotesWeb/ChangeStateinTrip", param).then((response) => {
    return response;
  });
}

function ChangeHideRemito(param) {
  return akeronService.make_put_request("HeaderTrip/UpdateHiddenDelivereds", param).then((response) => {
    return response;
  });
}
function getRutaWithRemitos(id) {
  return genericService.get("HeaderTrip/FindHeaderTripNewHistorial/" + id);
}

export const rutasService = {
  FILTER_DATE,
  FILTER_DRIVER,
  FILTER_VEHICLE,
  getRutaWithRemitos,
  ChangeStateRemito,
  filterOpen,
  addImages,
  getRuta,
  getAll,
  filter,
  remove,
  create,
  update,
  getRutaById,
  createNew,
  updateNew,
  OrderDelivered,
  ChangeHideRemito
};
