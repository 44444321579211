<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>

            <v-text-field data-vv-as="patente" label="Patente*" name="patent" v-model="item.patent" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
            <span class="red--text">{{ errors.first("patent") }}</span>

            <v-combobox
              v-model="item.type"
              data-vv-as="tipo"
              name="type"
              item-text="type"
              item-value="id"
              :items="tipoDeVehiculos"
              label="Tipo"
              autocomplete="off"
              v-validate="'required'"
              :disabled="isViewModal()"
            ></v-combobox>
            <span class="red--text">{{ errors.first("type") }}</span>

            <v-text-field data-vv-as="carga maxima" label="Carga maxima*" name="maxCharge" v-model="item.maxCharge" v-validate="'required'" suffix="Kg" :disabled="isViewModal()"></v-text-field>
            <span class="red--text">{{ errors.first("maxCharge") }}</span>

            <v-text-field data-vv-as="capacidad" :label="$t('Capacidad') + '*'" name="capacity" v-model="item.capacity" v-validate="'required'" :disabled="isViewModal()">
              <template slot="append">m<sup>3</sup></template>
            </v-text-field>
            <span class="red--text">{{ errors.first("capacity") }}</span>

            <v-combobox
              v-model="item.companyTransport"
              data-vv-as="transporte"
              name="type"
              item-text="nameTransport"
              item-value="id"
              :items="trasnportes"
              label="Transporte"
              autocomplete="off"
              v-validate="'required'"
              :disabled="isViewModal()"
            ></v-combobox>
            <span class="red--text">{{ errors.first("type") }}</span>

            <v-text-field data-vv-as="observacion" :label="$t('Observacion') + '*'" name="observation" v-model="item.observation" :disabled="isViewModal()"> </v-text-field>
            <span class="red--text">{{ errors.first("observation") }}</span>

            <v-checkbox :label="$t('Satelital')" v-model="item.satelital"></v-checkbox>
            <v-checkbox :label="$t('Activo')" v-model="item.isActive"></v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn v-if="!isViewModal()" color="success" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { typeVehicleService } from "@/libs/ws/typeVehicleService";
import { transportService } from "@/libs/ws/transportService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      tipoDeVehiculos: [],
      trasnportes: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadTipoVehiculo();
    this.loadTransportes();
  },
  methods: {
    loadTipoVehiculo() {
      typeVehicleService
        .filter(" ", 0, 100, 1)
        .then((response) => {
          this.tipoDeVehiculos = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTransportes() {
      transportService
        .filter(" ", 0, 1000, 1)
        .then((response) => {
          this.trasnportes = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    createItem() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success("Se actualizó correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            this.service
              .create(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success("Se creo correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
  },
};
</script>
